import p1 from './p1.jpg';
import p2 from './p2.jpg';
import p3 from './p3.jpg';
import p4 from './p4.jpg';
import p5 from './p5.jpg';
import p6 from './p6.jpg';
import p7 from './p7.jpg';
import p8 from './p8.jpg';
import p9 from './p9.jpg';
import p10 from './p10.jpg';
import p11 from './p11.jpg';
import p12 from './p12.jpg';
import p13 from './p13.jpg';
import p14 from './p14.jpg';
import p15 from './p15.jpg';
import p16 from './p16.jpg';
import p17 from './p17.jpg';
import p18 from './p18.jpg';
import p19 from './p19.jpg';

const config = {
  name: 'Poradnia Stomatologiczna Artdent',
  enName: 'Artdent dental clinic',
  exhibit: p2,
  images: [p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12, p13, p14, p15, p16, p17, p18, p19],
};

export default config;
