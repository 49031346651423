import p1 from './p1.jpeg';
import p2 from './p2.jpeg';
import p3 from './p3.jpeg';
import p4 from './p4.jpeg';
import p5 from './p5.jpeg';
import p6 from './p6.jpeg';
import p7 from './p7.jpeg';
import p8 from './p8.jpeg';
import p9 from './p9.jpeg';
import p10 from './p10.jpeg';
import p11 from './p11.jpeg';

const config = {
  name: 'Stomatologia estetyczna Estima, Tychy',
  enName: 'Aesthetic dentistry Estima, Tychy',
  exhibit: p3,
  images: [p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11],
};

export default config;
