import p1 from './p1.jpg';
import p2 from './p2.jpg';
import p3 from './p3.jpg';
import p4 from './p4.jpg';
import p5 from './p5.jpg';
import p6 from './p6.jpg';
import p7 from './p7.jpg';
import p8 from './p8.jpg';
import p9 from './p9.jpg';
import p10 from './p10.jpg';
import p11 from './p11.jpg';
import p12 from './p12.jpg';
import p13 from './p13.jpg';
import p14 from './p14.jpg';
import p15 from './p15.jpg';
import p16 from './p16.jpg';
import p17 from './p17.jpg';
import p18 from './p18.jpg';
import p19 from './p19.jpg';
import p20 from './p20.jpg';
import p21 from './p21.jpg';
import p22 from './p22.jpg';
import p23 from './p23.jpg';
import p24 from './p24.jpg';
import p25 from './p25.jpg';
import p26 from './p26.jpg';
import p27 from './p27.jpg';
import p28 from './p28.jpg';
import p29 from './p29.jpg';
import p30 from './p30.jpg';
import p31 from './p31.jpg';
import p32 from './p32.jpg';
import p33 from './p33.jpg';
import p34 from './p34.jpg';
import p35 from './p35.jpg';
import p36 from './p36.jpg';
import p37 from './p37.jpg';
import p38 from './p38.jpg';
import p39 from './p39.jpg';
import p40 from './p40.jpg';
import p41 from './p41.jpg';
import p42 from './p42.jpg';
import p43 from './p43.jpg';
import p44 from './p44.jpg';
import p45 from './p45.jpg';
import p46 from './p46.jpg';
import p47 from './p47.jpg';
import p48 from './p48.jpg';
import p49 from './p49.jpg';
import p50 from './p50.jpg';
import p51 from './p51.jpg';
import p52 from './p52.jpg';
import p53 from './p53.jpg';
import p54 from './p54.jpg';
import p55 from './p55.jpg';
import p56 from './p56.jpg';
import p57 from './p57.jpg';
import p58 from './p58.jpg';

const config = {
  name: 'Apartament w\xa0kamienicy w\xa0Krakowie',
  enName: 'Apartment in a\xa0tenement in\xa0Cracow',
  exhibit: p1,
  images: [
    p1,
    p2,
    p3,
    p4,
    p5,
    p6,
    p7,
    p8,
    p9,
    p10,
    p11,
    p12,
    p13,
    p14,
    p15,
    p16,
    p17,
    p18,
    p19,
    p20,
    p21,
    p22,
    p23,
    p24,
    p25,
    p26,
    p27,
    p28,
    p29,
    p30,
    p31,
    p32,
    p33,
    p34,
    p35,
    p36,
    p37,
    p38,
    p39,
    p40,
    p41,
    p42,
    p43,
    p44,
    p45,
    p46,
    p47,
    p48,
    p49,
    p50,
    p51,
    p52,
    p53,
    p54,
    p55,
    p56,
    p57,
    p58,
  ],
};

export default config;
